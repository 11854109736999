<template>
  <div class="mx-auto wrapper">
    <p class="list__header mb-10 mt-9" style="width: 1080px;">
      {{ title }}
    </p>
    <ApexChart
      max-width="100%"
      height="900"
      type="bar"
      :options="data.options"
      :series="data.series"
    />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Canales de referencia'
      },
      data: {
        type: Object,
        default: () => ({
          options: {},
          series: {}
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.list {
  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
}
.wrapper {
  width: 100%;
}
</style>

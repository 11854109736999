<template>
  <div class="bars__wrapper d-flex flex-column justify-center align-center">
    <p class="list__header">
      {{ title }}
    </p>
    <ApexChart
      width="160%"
      height="350"
      type="bar"
      class=""
      :options="data.options"
      :series="data.series"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Edad de los alumnos'
    },
    data: {
      type: Object,
      default: () => ({
        options: {},
        series: {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
}
.bars {
  &__wrapper {
    width: 100%;
    height: 480px;
    background: #EEF5F9;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 30px;
  }
}
</style>

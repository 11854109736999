export const settingsBars = {
  options: {
    colors: [
      '#1E88E5'
    ],
    chart: {
      id: 'vuechart-example'
    },
    xaxis: {
      categories: [
        '18-24',
        '25-35',
        '35-44',
        '45 a más'
      ],
      title: {
        text: 'Rango de edades'
      }
    },
    yaxis: {}

  },
  series: [{
    name: 'Cantidad de alumnos por rango de edad',
    data: [400, 600, 300, 800]
  }]
}

import { formatearNumero } from "../common"

export const settingsLinePlot = {
  series: [{
    name: 'S/.',
    data: []
  }],
  options: {
    chart: {
      height: 800
    },
    colors: [
      '#1E88E5'
    ],
    labels: [
      'Alto',
      'Moderado',
      'Bajo'
    ],
    stroke: {
      curve: 'smooth'
    },
    yaxis: {
      labels: {
        formatter: formatearNumero
      },
    },
    xaxis: {
      type: 'text'
    },
    responsive: [{
      breakpoint: 800,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  }
}

<template>
  <div :class="smAndDown ? 'px-0': 'px-10'" class="d-flex justify-center">
    <div v-if="!loading" style="max-width: 1600px;" clas="mx-auto">
      <v-row class="justify-lg-space-around mb-lg-6">
        <v-col cols="12" lg="12" class="d-flex justify-space-between align-center">
          <code-month-graph :data="graficaProgress" :subtitle="codeName" />
          <WidgetItem
            :number="numberWithCommas(lastMonth)"
            detail="Último mes"
            :state="formatPercent(lastMonthPercent)"
          />
          <Widget-Item
            :number="numberWithCommas(lastYear)"
            detail="Último año"
            :state="formatPercent(lastYearPercent)"
          />
        </v-col>
      </v-row>
      <v-row class="justify-space-around">
        <v-col cols="12" lg="6" :class="smAndDown ? '': ''">
          <UtilitiesObtainedGraph />
        </v-col>
        <v-col cols="12" lg="6" :class="smAndDown ? '': ''">
          <UtilitiesObtainedGraphDaily />
        </v-col>
      </v-row>
      <v-row style="width: 100%;" class="justify-space-around mb-lg-6" align="start">
        <v-col cols="12" lg="6" class="pa-0 ma-0" :class="smAndDown ? 'd-flex justify-center align-center': 'pl-0 pr-10'">
          <UniversityStudentsTable :items="universitiesList" />
        </v-col>
        <v-col cols="12" lg="6" class="pa-0 ma-0 d-flex" :class="smAndDown ? 'justify-center': 'justify-end'">
          <AgeStudentsGraph :data="graficaBarras" />
        </v-col>
      </v-row>
      <v-row class="justify-lg-space-around mb-lg-6">
        <v-col cols="12" lg="12" class="d-flex justify-center align-center">
          <ReferencesChannelsGraph :data="graficaStacked" />
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center" v-else>
      <v-progress-circular
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { settingsLinePlot } from '@/utils/graphs/ingresos'
import { settingsProgressBar } from '@/utils/graphs/progreso'
import { settingsStackedBar } from '@/utils/graphs/canales'
import { settingsBars } from '@/utils/graphs/edades'
import WidgetItem from '@/components/dashboardComponents/dashboard/WidgetItem.vue'
import CodeMonthGraph from '@/components/dashboardComponents/dashboard/CodeMonthGraph.vue'
import UniversityStudentsTable from '@/components/dashboardComponents/dashboard/UniversityStudentsTable.vue'
import AgeStudentsGraph from '@/components/dashboardComponents/dashboard/AgeStudentsGraph.vue'
import UtilitiesObtainedGraph from '@/components/dashboardComponents/dashboard/UtilitiesObtainedGraph.vue'
import UtilitiesObtainedGraphDaily from '@/components/dashboardComponents/dashboard/UtilitiesObtainedGraphDaily.vue'
import ReferencesChannelsGraph from '@/components/dashboardComponents/dashboard/ReferencesChannelsGraph.vue'

export default {
  components: {
    WidgetItem,
    CodeMonthGraph,
    UniversityStudentsTable,
    AgeStudentsGraph,
    UtilitiesObtainedGraph,
    UtilitiesObtainedGraphDaily,
    ReferencesChannelsGraph
  },
  data: () => ({
    graficLine: settingsLinePlot,
    graficaProgress: settingsProgressBar,
    graficaStacked: settingsStackedBar,
    graficaBarras: settingsBars,
    codeName: '',
    universitiesList: [],
    lastMonth: 0,
    lastMonthPercent: 0,
    lastYear: 0,
    lastYearPercent: 0,
    loading: true
  }),
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    formatPercent(decimal) {
      const number = Math.round(decimal * 10000) / 100
      return `${number > 0? '+': ''}${(number).toFixed(2)}%`;
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    async fetchCodes () {
      const { data } = await axios.get('api/admin/matriculas/codigos_referencia/codigo_mas_usado_mes/')
      this.graficaProgress.series = [data.cantidad_matriculas]
      this.codeName = data.codigo
    },
    async fetchStudentsByAge () {
      const { data } = await axios.get('api/admin/graficas/cantidad_estudiantes_por_edad/')
      this.graficaBarras.series[0].data = Object.values(data)
      const labels = Object.keys(data).map(item => item.slice(6,).replace('_', '-'))
      labels.pop()
      labels.push('no_especificado')
      this.graficaBarras.options.xaxis.categories = labels
    },
    async fetchStudentsByUniversity () {
      const { data } = await axios.get('api/admin/graficas/cantidad_estudiantes_por_universidad/')
      this.universitiesList = data
    },
    async fetchWidgetMonth () {
      const { data } = await axios.get('api/admin/matriculas/pagos/ventas_ultimo_mes/')
      this.lastMonth = data.ventas_ultimo_mes

      const percentResponse = await axios.get('api/admin/matriculas/pagos/porcentaje_ventas_ultimo_mes/')
      this.lastMonthPercent = percentResponse.data.porcentaje_ventas
    },
    async fetchWidgetYear () {
      const { data } = await axios.get('api/admin/matriculas/pagos/ventas_ultimo_anio/')
      this.lastYear = data.ventas_ultimo_anio

      const percentResponse = await axios.get('api/admin/matriculas/pagos/porcentaje_ventas_ultimo_anio/')
      this.lastYearPercent = percentResponse.data.porcentaje_ventas_anual
    },
    async fetchReferencesChannels () {
      const { data } = await axios.get('api/admin/graficas/referidos_por_mes/')
      // const uniquest = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre']
      // const items = data.map(item => item.meses).map(item => item.map(element => element.mes)).flat()
      // const months = [...new Set(items)]
      const arrayOfArrays = data.map(item => item.meses).map(item => item.map(element => element.mes))
      const arrayOfLengths = data.map(item => item.meses.length)
      const max = Math.max(...arrayOfLengths)
      const index = arrayOfLengths.indexOf(max)
      // console.log('maximos: ', arrayOfArrays[index])
      const months = arrayOfArrays[index]
      this.graficaStacked.options.xaxis.categories = months
      this.graficaStacked.series = await data.map(item => {
        const values = new Array(months.length).fill(0)
        for (const i of item.meses) {
          const index = months.indexOf(i.mes)
          values[index] = i.cantidad_usuarios
        }
        return {
          name: item.nombre,
          data: values
        }
      })
    }
  },
  async created () {
    await this.fetchCodes()
    await this.fetchStudentsByAge()
    await this.fetchStudentsByUniversity()
    await this.fetchWidgetMonth()
    await this.fetchWidgetYear()
    await this.fetchStudentsByAge()
    await this.fetchReferencesChannels()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.list {
  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
}
.bars {
  &__wrapper {
    width: 663px;
    height: 480px;
    background: #EEF5F9;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 30px;
  }
}
</style>

<template>
  <div class="list__container d-flex flex-column align-center pt-7" :class="$vuetify.breakpoint.width < 1540 ? 'mb-5 mx-auto': 'mb-0'">
    <p class="list__header mb-10">
      {{ title }}
    </p>
    <div class="list">
      <div
        v-for="(item, index) in items"
        :key="index"
        v-ripple
        class="list__item d-flex align-center justify-space-between px-4 mx-auto mb-6 pointer"
      >
        <p class="list__text ma-0">
          {{ item.nombre }}
        </p>
        <p class="list__points ma-0">
          {{ item.cantidad_usuarios_matriculados }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Universidad de los alumnos'
    },
    items: {
      type: Array,
      default: () => []
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  max-height: 320px;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: 5px; /* for Firefox */
  &::-webkit-scrollbar-track {
      background-color: #EEF5F9;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(219, 219, 219);
    border-radius: 5px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    // display: none; /* for Chrome, Safari, and Opera */
  }
  &__container {
    width: 100%;
    height: 480px;
    background: #EEF5F9;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 30px;
  }
  &__item {
    max-width: 75%;
    height: 42px;
    background: #EEF5F9;
    border: 1px solid #D8D8D8;
    border-radius: 100px;
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #455A64;
  }
  &__points {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #1E88E5;
  }
  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
}
</style>

export const downloadCsv = (csvContent, filename) => {
    const blob = new Blob(["\ufeff", csvContent], {type: 'text/csv;'});
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
}

export const formatearNumero = (numero) => {
    const sufijos = ["", "k", "M", "B", "T"];
    let absNumero = Math.abs(numero);
    let sufijoIndex = 0;
    while (absNumero >= 1000 && sufijoIndex < sufijos.length - 1) {
        absNumero /= 1000;
        sufijoIndex++;
    }
    const numeroFormateado = (numero < 0 ? '-' : '') + absNumero.toFixed(1) + sufijos[sufijoIndex];
    return numeroFormateado;
}
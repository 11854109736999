<template>
  <div class="d-flex justify-center align-center flex-column w-100">
    <p class="list__header mx-auto" :class="mdAndDown ? 'mx-auto': 'ml-0 mr-auto'">
      Ingresos mensuales
    </p>
    <div style="width: 100%;" class="d-flex align-center">
      <v-menu
          ref="startDate"
          v-model="startDateMenu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="ml-2 chart-input"
            v-model="startDate"
            label="Mes de inicio"
            prepend-icon="mdi-calendar-month"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="startDate"
            no-title
            scrollable
            locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="startDateMenu = false">Cancelar</v-btn>
          <v-btn text color="primary" @click="$refs.startDate.save(startDate); datesUpdated();">OK</v-btn>
        </v-date-picker>
      </v-menu>

      <v-menu
          ref="endDate"
          v-model="endDateMenu"
          :close-on-content-click="false"
          :return-value.sync="endDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="chart-input mx-6"
            v-model="endDate"
            label="Mes de fin"
            prepend-icon="mdi-calendar-month"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="endDate"
            no-title
            scrollable
            locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="endDateMenu = false">Cancelar</v-btn>
          <v-btn text color="primary" @click="$refs.endDate.save(endDate); datesUpdated();">OK</v-btn>
        </v-date-picker>
      </v-menu>

      <v-select
        class="ml-2 chart-input"
        v-model="currentChartType"
        :items="chartTypes" 
        item-text="text"
        item-id="value"
        @change="datesUpdated()"
      >
      </v-select>
    </div>
    <p class="amount-summary" @click="$router.push({name: 'Ingresos'})">
      Ingreso total obtenido desde {{ startDate | moment("DD/MM/YYYY") }} hasta {{ endDate | moment("DD/MM/YYYY") }}: <b>S/. {{ montoTotal | roundTwoDecimals }}</b>
    </p>
    <ApexChart
      ref="lineRef"
      width="190%"
      height="400"
      :class="mdAndDown ? 'mx-auto': 'ml-0 mr-auto'"
      type="line"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {settingsLinePlot} from '@/utils/graphs/ingresos'

export default {
  props: {
    title: {
      type: String,
      default: 'Ingresos obtenidos'
    }
  },
  data() {
    return {
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      series: settingsLinePlot.series,
      options: settingsLinePlot.options,
      montoTotal: 0,
      data: [],
      currentChartType: 'DAILY',
      chartTypes: [
        { value: 'MONTHLY', text: 'Mensual'},
        { value: 'DAILY', text: 'Diario'},
        // { value: 'CUMULATIVE_DAILY', text: 'Diario acumulativo'},
      ]
    }
  },
  filters: {
    roundTwoDecimals: (value) => {
      return Math.round(value * 100) / 100
    }
  },
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },
  },
  methods: {
    getLastTwelveMonths() {
      const temporaryStartDate = new Date()
      temporaryStartDate.setMonth(temporaryStartDate.getUTCMonth())
      temporaryStartDate.setDate(1)
      this.startDate = temporaryStartDate.toISOString().substring(0, 10)

      const temporaryEndDate = new Date()
      temporaryEndDate.setMonth(temporaryEndDate.getUTCMonth() + 1)
      temporaryEndDate.setDate(-1)
      this.endDate = temporaryEndDate.toISOString().substring(0, 10)
    },
    datesUpdated() {
      const startDate = new Date(this.startDate)
      const endDate = new Date(this.endDate)
      this.updateUtilities(startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10), this.currentChartType)
    },
    async fetchUtilities(startDate = null, endDate = null, chartType = 'MONTHLY') {
      let url = chartType === 'MONTHLY'? 'api/admin/graficas/pagos_por_mes/?': 'api/admin/graficas/pagos_por_dia/?'
      if (startDate) { url = `${url}startDate=${startDate}&` }
      if (endDate) { url = `${url}endDate=${endDate}`}
      const {data} = await axios.get(url)
      return data
    },
    formatUtilitiesData(data, currentChartType) {
      this.montoTotal = 0
      if (currentChartType === 'MONTHLY') {
        const series = []
        const categories = []
        data.forEach(item => {
          this.montoTotal += item.monto_total
          series.push(item.monto_total)
          categories.push(item.mes.slice(0, 3))
        })
        return { series, categories }
      } else if (currentChartType === 'DAILY') {
        return {
          series: data.map(item => {
            this.montoTotal += item.monto_total
            return [item.fecha_deposito, item.monto_total]
          }),
          categories: []
        }
      } else {
        let acumulativeTotal = 0
        return {
          series: data.map(item => [item.fecha_deposito, acumulativeTotal+=item.monto_total]),
          categories: []
        }
      }
    },
    async updateUtilities(startDate = null, endDate = null, chartType) {
      const data = await this.fetchUtilities(startDate, endDate, chartType)
      const {series, categories} = this.formatUtilitiesData(data, chartType)
      this.series[0].data = series
      this.options.xaxis.categories = categories
      this.options.xaxis.type = chartType === 'MONTHLY'? 'text': 'datetime'
      this.$refs.lineRef.refresh()
    },
  },
  mounted() {
    this.getLastTwelveMonths()
    this.datesUpdated()
  }
}
</script>

<style lang="scss" scoped>
.list {
  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
}
.chart-input {
  max-width: 200px;
}
.amount-summary {
  font-size: 13px;
  color: #455A64;
  align-self: self-start;
}
</style>

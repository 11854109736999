export const settingsStackedBar = {
  series: [{
    name: 'Por Referencia',
    data: []
  }, {
    name: 'Youtube',
    data: []
  }, {
    name: 'Buscadores (Google)',
    data: []
  }, {
    name: 'Instagram',
    data: []
  }, {
    name: 'Facebook',
    data: []
  }, {
    name: 'Otros',
    data: []
  }],
  options: {
    colors: [
      '#1E88E5',
      '#00ACF3',
      '#00C9DF',
      '#00E1B5',
      '#93F188',
      '#F9F871'
    ],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      }
    },
    xaxis: {
      categories: []
    },
    legend: {
      position: 'bottom',
      align: 'right',
      offsetY: 20
    },
    fill: {
      opacity: 1
    }
  }
}

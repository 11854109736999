export const settingsProgressBar = {
  options: {
    chart: {
      type: 'radialBar',
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '40%',
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
            color: '#888',
            fontSize: '17px'
          },
          value: {
            formatter: function(val) {
              return parseInt(val);
            },
            color: '#2196F3',
            fontSize: '25px',
            offsetY: 7,
            show: true,
          }
        }
      },
    },
  },
  series: [40]
}
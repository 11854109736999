<template>
  <div class="code d-flex">
    <div class="d-flex flex-column code__label">
      <p class="code__header d-flex flex-column">
        {{ title }}
      </p>
      <p class="mt-8">
        {{ subtitle }}
      </p>
    </div>
    <ApexChart
      width="200"
      height="200"
      type="radialBar"
      class="ma-0 pa-0"
      :options="data.options"
      :series="data.series"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Código del mes'
    },
    subtitle: {
      type: String,
      default: 'Nombre del código'
    },
    data: {
      type: Object,
      default: () => ({
        options: {},
        series: {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.code {
  position: relative;
  width: 404px;
  height: 201px;
  background: #EEF5F9;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 27px;
  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
  &__label {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #2196F3;
  }
}
</style>

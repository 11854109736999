<template>
  <div class="label d-flex flex-column">
    <p class="label__number text-center">
      {{ number }}
    </p>
    <div class="label__details d-flex justify-space-around ma-0">
      <p class="label__text">
        {{ detail }}
      </p>
      <div class="label__tag ma-0 d-flex justify-center align-center px-1" :class="detail === 'Último mes' ? 'label__tag--month': 'label__tag--year'">
        <v-img
          :src="upOrDown ? require('@/assets/images/icon/dashboard-up.svg'): require('@/assets/images/icon/dashboard-down.svg')"
          width="16"
          height="16"
        />
        <p class="label__tendency ma-0" :class="detail === 'Último mes' ? 'label__tendency--month': 'label__tendency--year'">
          {{ state }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      default: ''
    },
    detail: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: '0'
    }
  },
  computed: {
    upOrDown () {
      return this.state[0] === '+'
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  &__number {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #2C2C2C;
  }
  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #8E8E8E;
    flex: none;
    order: 0;
    align-self: flex-start;
    flex-grow: 0;
    margin: 0px 16px;
  }
  &__tag {
    height: 20px;
    border-radius: 5px;
    flex: none;
    order: 1;
    align-self: flex-end;
    flex-grow: 0;
    margin: 0px 16px;
    &--month {
      background: #C3EE83;
    }
    &--year {
      background: #FE9F99;
    }
  }
  &__tendency {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
    &--month {
      color: #489019;
    }
    &--year {
      color: #B52A54;
    }
  }
}
</style>
